import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'login',
        children: [
            {
                path: '/',
                redirect: '/login',
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('../views/login.vue')
            },
            {
                path: '/welcome',
                name: 'welcome',
                component: () => import('../views/welcome.vue')
            },
            {
                path: '/myself',
                name: 'myself',
                component: () => import('../views/myself.vue')
            },
            {
                path: '/birthday',
                name: 'birthday',
                component: () => import('../views/birthday.vue')
            },
            {
                path: '/nfc',
                name: 'nfc',
                component: () => import('../views/nfc.vue')
            },
            {
                path: '/demo',
                name: 'demo',
                component: () => import('../views/demo.vue')
            },
            {
                path: '/novel',
                name: 'novel',
                component: () => import('../views/novel.vue')
            },
            {
                path: '/updateNovel',
                name: 'updateNovel',
                component: () => import('../views/updateNovel.vue')
            },
        ],
    },

]


const router = createRouter({
    history: createWebHashHistory(),
    routes
})


export default router


